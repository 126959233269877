import { configureStore } from "@reduxjs/toolkit";

import { apiSlice } from "./api/apiSlice";
import { apiSliceUserManager } from "./api/apiSlice";
import authSliceReducer from "./auth/authSlice";
import layoutSliceReducer from "./layout/layoutSlice";
import ecommerceSliceReducer from "./ecommerce/ecommerceSlice";
import companySliceReducer from "./company/companySlice";
import buildingsliceReducer from "./Building/buildingslice";
import userManagerReducer from "./UserManager/userManagerSlice";
import accessUserReducer from "./Users/Accessusers/accessUserSlice";
import facilitySliceReducer from "./Facility/facilitySlice";
import masterSliceReducer from "./master/masterslice";
export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    [apiSliceUserManager.reducerPath]: apiSliceUserManager.reducer,
    auth: authSliceReducer,
    layout: layoutSliceReducer,
    ecommerce: ecommerceSliceReducer,
    company: companySliceReducer,
    building: buildingsliceReducer,
    userManager: userManagerReducer,
    accessUser: accessUserReducer,
    facility: facilitySliceReducer,
    masters: masterSliceReducer
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddlewares) =>
    getDefaultMiddlewares().concat(
      apiSlice.middleware,
      apiSliceUserManager.middleware
    ),
});
